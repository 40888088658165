
// import { useEffect, useState } from 'react';

// const useScroll = () => {
//   const [scrollVar, setScrollVar] = useState(0);

//   useEffect(() => {
//     const handleScroll = () => {
//       const htmlElement = document.documentElement;
//       const percentOfScreenHeightScrolled =
//         (htmlElement.scrollTop / htmlElement.clientHeight) * 100;
//       setScrollVar(Math.min(percentOfScreenHeightScrolled, 100));
//       console.log('pos:' + Math.min(percentOfScreenHeightScrolled * 100, 100)); 
//     };

//     // Initial call to setScrollVar
//     handleScroll();

//     // Event listeners for scroll and resize
//     window.addEventListener('scroll', handleScroll);
//     window.addEventListener('resize', handleScroll);
   
//     // Cleanup function to remove event listeners
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//       window.removeEventListener('resize', handleScroll);
//     };
//   }, []);

//   return scrollVar;
// };

// export default useScroll;


// window.addEventListenner("scroll", setScrollVar)
// window.addEventListenner("resize", setScrollVar)

// function setScrollVar(){
//     const htmlElement = document.documentelement;
//     const percentOfScreenHeightScrolled = htmlElement.scrollTop / htmlElement.clientHeight;

//     console.log(Math.min(percentOfScreenHeightScrolled * 100, 100));    
//     htmlElement.style.setProperty(
//         "--scroll", 
//         Math.min(percentOfScreenHeightScrolled * 100, 100)
//         );
// }

// setScrollVar()

import React, { useEffect } from 'react';

const ScrollComponent = () => {
  useEffect(() => {
    const setScrollVar = () => {
      const htmlElement = document.documentElement;
      const percentOfScreenHeightScrolled =
        (htmlElement.scrollTop / htmlElement.clientHeight) * 100;
      const scrollValue = Math.min(percentOfScreenHeightScrolled, 100);
      //console.log('pos:' + scrollValue);
      htmlElement.style.setProperty('--scroll', `${scrollValue}`);
    };

    // Initial call to setScrollVar
    setScrollVar();

    // Event listeners for scroll and resize
    window.addEventListener('scroll', setScrollVar);
    window.addEventListener('resize', setScrollVar);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('scroll', setScrollVar);
      window.removeEventListener('resize', setScrollVar);
    };
  }, []);

  const observer = new IntersectionObserver((entries) => {
    for (let i = entries.length - 1; i >= 0; i--) {
      const entry = entries[i];
      if (entry.isIntersecting) {
        document.querySelectorAll('[data-img]').forEach((img) => {
          img.classList.remove('show');
        });
        const img = document.querySelector(entry.target.dataset.imgToShow);
        img?.classList.add('show');
        break;
      }
    }
  });

  document.querySelectorAll('[data-img-to-show]').forEach((section) => {
    observer.observe(section);
  });

 
};

export default ScrollComponent;

