import React, { useEffect, useState } from 'react';

const images = require.context('../src/images/projects', false, /\.(jpg|jpeg|png|webp|svg)$/);
const imageList = images.keys().map(image => images(image));  

function shuffleArray(array) {
  // Shuffle the array using Fisher-Yates algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function Works() {

  const [shuffledImages, setShuffledImages] = useState([]);

  useEffect(() => {
    // Shuffle images on mount or whenever the imageList changes
    setShuffledImages(shuffleArray([...imageList]));
  }, []); // Removed imageList from the dependency array

  return (
    <div className='works'>
      {shuffledImages.map((image, index) => (
        <img key={index} src={`${image}`} alt={`Thumbnail ${index}`} />
      ))}
    </div>
  );
}


export default Works;