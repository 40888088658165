

import React from 'react';

import sunbowlImage from './images/thumbs/sunbowl.png';
import realctorImage from './images/thumbs/realctor.png';
import molsonCanadaImage from './images/thumbs/molson-canada.png';
import memareeImage from './images/thumbs/memaree.png';
import forStarsOnlyImage from './images/thumbs/forstarsonly.png';
import dashboardImage from './images/thumbs/dashboard.png';
import carMediaImage from './images/thumbs/carmedia-1.png';
import sneakerStoreImage from './images/thumbs/sneaker.png';

import alpacaImage from './images/thumbs/alpaca-2.png';
import goliveImage from './images/thumbs/golive-1.png';
import snippetImage from './images/thumbs/snippet.png';
import hillsImage from './images/thumbs/hills.png';
import mozImage from './images/thumbs/moz.png';
import avaraImage from './images/thumbs/3d.png';
import emailImage from './images/thumbs/email-app.png';
// import acmeImage from './images/thumbs/acme.png';

const portfolio = [
  // { thumb: acmeImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=0%3A1&type=design&node-id=85-39753&viewport=-35039%2C411%2C0.42&t=lDLxFiaKabNhrJRf-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: sunbowlImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=213%3A33342&type=design&node-id=213-33343&viewport=948%2C493%2C0.17&t=szHTwLqeuwz0jUDE-1&scaling=min-zoom&mode=design", status: true },
  { thumb: realctorImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A5436&type=design&node-id=125-5437&viewport=948%2C493%2C0.25&t=qRA468dMwNGiAjfI-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: memareeImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A6709&type=design&node-id=125-6710&viewport=948%2C493%2C0.14&t=mR2UmSMjcfYl1znb-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: forStarsOnlyImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A12193&type=design&node-id=125-12194&viewport=948%2C493%2C0.16&t=GFQOx4UB8juOuUct-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: dashboardImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A7996&type=design&node-id=125-7997&viewport=948%2C493%2C0.12&t=InVY04FeU3xMeJmh-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: carMediaImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A5394&type=design&node-id=125-5395&viewport=948%2C493%2C0.1&t=NHm21edRBObkPGc3-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: sneakerStoreImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A12221&type=design&node-id=125-12222&viewport=948%2C493%2C0.11&t=CA7eaGkBszXzGVDh-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: alpacaImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A12525&type=design&node-id=125-12526&viewport=948%2C493%2C0.17&t=Bh31MrAEhjwNC0SJ-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: goliveImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A13472&type=design&node-id=125-13473&viewport=948%2C493%2C0.17&t=1XS9A04bLhq6ioA1-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: snippetImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A13495&type=design&node-id=125-13496&viewport=948%2C493%2C0.17&t=Y9j1qydWHevwA4SP-1&scaling=min-zoom&mode=design" , status: true},
  { thumb: molsonCanadaImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A6680&type=design&node-id=125-6681&viewport=948%2C493%2C0.17&t=cOFhsBAnJdsKqDq0-1&scaling=min-zoom&mode=design", status: true },
 { thumb: hillsImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=125%3A13546&type=design&node-id=125-13547&viewport=948%2C493%2C0.07&t=0BFa5xfxjnpNXjxY-1&scaling=min-zoom&mode=design" , status: true},
 { thumb: mozImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=197%3A32876&type=design&node-id=197-32877&viewport=2901%2C1690%2C0.25&t=cpQkgu5FyakkozGp-1&scaling=min-zoom&mode=design" , status: true},
 { thumb: avaraImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=197%3A33254&type=design&node-id=197-33255&viewport=1032%2C849%2C0.39&t=Q53pRMSfb7POyaR2-1&scaling=min-zoom&mode=design" , status: true},
{ thumb: emailImage, project: "https://www.figma.com/proto/zhjUSHO96Wbu5rgbvEUQXs/Portfolio?page-id=197%3A33294&type=design&node-id=197-33295&viewport=928%2C496%2C0.15&t=q3cpX8C0jCBQtSbw-1&scaling=min-zoom&mode=design" , status: true}
];

const Portfolio = () => {
  return (
    <div className='thumbs'>
      {/* Menu */}
      {portfolio.map((item, index) => (
        item.status ? (
          <a key={index} href={item.project} target="_blank" rel="noopener noreferrer">
            <img src={item.thumb} alt={`Thumbnail ${index}`} />
            {/* <p>{item.project}</p> */}
          </a>
        ) : null
      ))}
    </div>
  );
};



export default Portfolio;





















