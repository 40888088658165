import React, { useEffect } from 'react';
import './App.scss';
import Portfolio from './portfolio'
import Works from './Works'
import Typewriter from './components/TypingEffect'
import { CaretDown,CaretUp, CaretUpDown,User } from "@phosphor-icons/react";
import Me from './images/me.png'
import Scroller from './images/scroller.gif'

import ReactGA from 'react-ga';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from '@vercel/analytics/react';

import ScrollComponent from './components/ScrollComponent';

import VideoBG from './video/bg21.mp4'
import PosterImage from './images/background.png'
import DesVideoBG from './video/des-7.mp4'
import DesVideoBGWEB from './video/des-7.webm'
import CodeVideoBG from './video/bg10.mp4'
import SecondVideoBG from './video/des-7.mp4'

import Realctor from './video/realctor.mp4'
import Sneaker from './video/sneaker.mp4'
import Carmedia from './video/carmedia.mp4'
import Molson from './video/molson.mp4'
import Sunbowl from './video/sunbowl.mp4'
import Memaree from './video/memaree.mp4'
import Dashboard from './video/dashboard.mp4'
import Cashmere from './video/cashmere.mp4'
import Alpaca from './video/alpaca.mp4'
import Golive from './video/golive.mp4'
import OnlyForStars from './video/OnlyForStars.mp4'


import LoadingVideo from './images/loading.png'

const TRACKING_ID = "G-Z39GYYLBEF"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

ReactGA.initialize(TRACKING_ID, {
  debug: true,
  titleCase: false,
  gaOptions: {
  userId: 123,
  },
  });

function App() {

  //ScrollComponent();

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
    
  },[]
  );
  
  
  return (
    <div className="App">
      <header className="App-header">
      
      <video autoPlay loop muted playsInline className="bg-vid">
          <source src={DesVideoBG} type="video/mp4" />
          <source src={DesVideoBGWEB} type="video/webm" />
      </video>

        <h1 >
            <Typewriter text={"<Hello/>"} delay={150} infinite={false} />
        </h1>
        <h4>
           <Typewriter text={"I'm Bruno Neves."} delay={150} initialDelay={1200} infinite={false} />
        </h4>
        <span className='subTitle'><Typewriter text={"UX/UI Designer & UI Developer"} initialDelay={3800} delay={80} infinite={false} /></span>
       
        <span className="arrows"><span className="scrollDown">scroll down</span><CaretDown size={36} color="#ffffff" weight="thin"/></span>
        
      </header>
     
      <section className='about'>
            <h1 className=''>Who I Am</h1>
            <p className='sectionTitle'>
            I'm a UX/UI Designer & UI Developer based in Canada with over 16 years of experience. I have a passion for creating emotional experiences that converge at the intersection of art, design and technology.
            <br/> <br/>
            My expertise covers the entire design lifecycle, including ideation, wireframing, prototyping, and design systems. I am skilled in various tools such as Figma, Sketch, Photoshop, XD... Also in frontend development.
            </p>
          
            <span className="arrows"><CaretDown size={36} color="#000000" weight="thin"/></span>

      </section>

     

      {/* <section className='about about-step3'>
            
           <span>
             
              <h1 className=''>UI Development</h1>
              <p>
              I am proficient in HTML, CSS, JavaScript, ReactJS/Remix, SASS, and Swift/SwiftUI, with the ability to initiate projects independently or collaborate with development teams. I offer comprehensive support to ensure the successful completion of products.
            </p>
              
           </span>
           <video autoPlay loop muted className="bg-vid2"><source src={CodeVideoBG} type="video/mp4" /> </video>

      </section> */}


      <section className='works'>
            
          
              
               <h1>A quick view of my work.</h1>
               <span>
                  <h3>Realtor.ca Redesign Concept<span>/ UX/UI Design.</span></h3>
                  <p className='description'>
                  I created a fresh design concept for Realtor.ca, focusing on user experience, interface design, and animation. The concept aimed to set a new standard for online real estate platforms, offering a visually appealing and user-friendly interface.  
                  </p>
                  <video  autoPlay loop muted className="work"><source src={Realctor} type="video/mp4" /> </video>
              </span>
              {/* <br/><br/><br/>
              <span>
                  <h3>Footer Locker <span>/ Sneaker Store</span></h3>
                
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Sneaker} type="video/mp4" /> </video>
              </span> */}
              <br/><br/><br/>
              <span>
                  <h3>Dashboard <span>/ UX/UI Design.</span></h3>
                  <p className='description'>
                  I created a user-friendly data dashboard that makes it easy to understand complex data information. To keep everything consistent and easy to update, I also created a design system specifically for data dashboards.  
                  </p>
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Dashboard} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/>
              {/* <span>
                  <h3>Carmedia <span>/ A powerful identity for the dealership.</span></h3>
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Carmedia} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/> */}
              {/* <span>
                  <h3>Molson Canada <span>/ Personalized Molson Canadian Label App For Surface</span></h3>
                 
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Molson} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/> */}
              {/* <span>
                  <h3>Sunbowl <span>/ Sales Landingpage</span></h3>
                 
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Sunbowl} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/> */}
              <span>
                  <h3>The cashmere <span>/ Concept</span></h3>
                  <p className='description'>
                  As a design-centric professional at Sunbowl, my primary responsibility involves curating outstanding ecommerce solutions exclusively tailored for the Shopify platform. With a profound comprehension of design principles and an acute focus on user experience, I specialize in sculpting visually striking and intuitively navigable online stores. I also have experience in creating plugins for Shopify using liquid.
                  </p>
                 
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Cashmere} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/>
              {/* <span>
                  <h3>Memaree <span>/ Social Media</span></h3>
                  
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Memaree} type="video/mp4" /> </video>
              </span> */}
               <span>
                  <h3>Alpaca <span>/ UX/UI design</span></h3>
                  <p className='description'>
                  In my role, I focused on UX and UI design, creating various webpages for both internal teams and the public. I also contributed to design systems, illustrations to enhance visual appeal. Additionally, I acted as a bridge between the design and development teams, ensuring seamless collaboration and effective implementation of design concepts. Through collaborative efforts, I aimed to improve aesthetics, streamline processes, and use data for better decision-making. I also participated in design reviews to ensure we followed best practices, aiming to help Alpaca succeed in the long term. 
                  </p>
                  <video  poster={LoadingVideo} autoPlay loop muted className="work"><source src={Alpaca} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/>
              <span>
                  <h3>Golive TV <span>/ UX/UI Design and UI Development</span></h3>
                  <p className='smallTitle'>Check the updated version <a href='https://apps.apple.com/ca/app/golive-tv/id1430285217'>iOS</a> and  <a href='https://play.google.com/store/apps/details?id=ca.golivetv.app&hl=en_US&gl=US'>Android</a>
                  </p>
                  <p className='description'>
                  This is a live TV broadcasting startup based in Toronto, Canada. As Golive TV is a small company, I undertook both design and development responsibilities. My roles encompassed designing and coding both the first and second versions of this app, ensuring compatibility with both Android and iOS platforms. For the first version, I developed the iOS version using Swift, while the second version utilized React Native.
                  </p>
                 
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={Golive} type="video/mp4" /> </video>
              </span>
              <br/><br/><br/>
              <span>
                  <h3>For Stars Only <span>/ UX/UI Design and UI Development</span></h3>
                  <p className='description'>
                  For Stars Only is a horoscope application available on the Apple App Store for iPhone and iPad users. Developed as a personal project, I designed and coded every aspect of this app on my free time, with my passion for both UX/UI design and UI development. From conceptualization to launch, I led the entire process, ensuring a seamless and visually engaging user experience. With a keen eye for detail and a commitment to excellence, I brought the celestial world of horoscopes to life, making For Stars Only a must-have for astrology enthusiasts.
                  </p>
                  <video poster={LoadingVideo} autoPlay loop muted className="work"><source src={OnlyForStars} type="video/mp4" /> </video>
              </span>

              <h1>I'm excited to showcase my top projects.<br/>Let's stay connected!</h1>
          
           
 
       </section>

      


      <section className="portfolio">
            <p>
                <h1>A Few More</h1>
                <spam>*More details on my responsibilities and contributions to the project will be discussed in-depth during the interview.</spam>
            </p>
            <Portfolio/>
      </section>
      
      <footer>
        <span className="arrows"><CaretUp size={36} color="#000000" weight="thin"/></span>
        <p>
            You're welcome to connect with me through <a href="https://mail.google.com/mail/?view=cm&fs=1&to=sevenb@gmail.com" target='_blank' rel="noopener noreferrer">Gmail</a>, <a href='https://www.linkedin.com/in/brneves/' target='_blank' rel="noopener noreferrer" >Linkedin</a> and  <a href='https://github.com/sevenb' target='_blank' rel="noopener noreferrer">Github</a>.
            </p>
        <h3>www.brunoneves.com - Created with ReactJS and hosted on Vercel.<br/> Made in Canada ❤️</h3><br/> <br/> <br/> <br/> 
      </footer>

      <Analytics />
      <SpeedInsights />
      
    </div>
  );
}

export default App;

