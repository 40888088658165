
// import React from 'react';
// import { useState, useEffect } from 'react';

// const Typewriter = ({ text, initialDelay, delay, infinite }) => {
//   const [currentText, setCurrentText] = useState('');
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     let timeout;

//     if (currentIndex <= ( text.length - 1 ) ) {
//       timeout = setTimeout(() => {
//         setCurrentText(prevText => prevText + text[currentIndex]);
//         setCurrentIndex(prevIndex => prevIndex + 1);
//       }, delay);
      
//     } else if (infinite) { // ADD THIS CHECK
//       setCurrentIndex(0);
//       setCurrentText('');
//     }
    
//     return () => clearTimeout(timeout);
//   }, [currentIndex, delay, infinite, text]);

//   return <span>{currentText}</span>;
// };

// export default Typewriter;


// import React from 'react';
// import { useState, useEffect } from 'react';

// const Typewriter = ({ text, initialDelay, delay, infinite }) => {
//   const [currentText, setCurrentText] = useState('');
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     let timeout;

//     if (currentIndex <= (text.length - 1)) {
//       timeout = setTimeout(() => {
//         setCurrentText((prevText) => prevText + text[currentIndex]);
//         setCurrentIndex((prevIndex) => prevIndex + 1);
//       }, delay);
//     } else if (infinite) {
//       setCurrentIndex(0);
//       setCurrentText('');
//     }

//     return () => clearTimeout(timeout);
//   }, [currentIndex, delay, infinite, text]);

//   return <span className='headerTitle'>{currentIndex < text.length ? currentText + "|" : currentText + ""}</span>;
// };

// export default Typewriter;

import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, initialDelay=0, delay, infinite, color='#ffffff' }) => {
  const [startTyping, setStartTyping] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartTyping(true);
      clearInterval(interval); // Stop the interval after initialDelay
    }, initialDelay);
    
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [initialDelay]);

  return startTyping ? <Typing text={text} delay={delay} infinite={infinite} /> : null;
};

function Typing({ text, delay, infinite }) {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex <= text.length - 1) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      setCurrentIndex(0);
      setCurrentText('');
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return <span className='headerTitle'>{currentIndex < text.length ? currentText + '|' : currentText}</span>;
}

export default Typewriter;






